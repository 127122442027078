<script setup lang="ts"></script>

<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_9339_21781)">
      <path
        d="M18.1875 8.64325C19.584 9.24325 20.4375 10.0518 20.4375 10.9375C20.4375 12.8012 16.6597 14.3125 12 14.3125C7.34025 14.3125 3.5625 12.8012 3.5625 10.9375C3.5625 9.07375 7.34025 7.5625 12 7.5625"
        stroke="black"
        stroke-width="1.125"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.5625 10.9375V12.0625C3.5625 16.4125 7.34025 19.9375 12 19.9375C16.6597 19.9375 20.4375 16.4125 20.4375 12.0625V10.9375"
        stroke="black"
        stroke-width="1.125"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12 14.3125C8.8935 14.3125 6.375 13.3052 6.375 12.0625C6.375 11.0552 8.03025 10.2025 10.3125 9.91525"
        stroke="black"
        stroke-width="1.125"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.1875 12.0625H10.3125"
        stroke="black"
        stroke-width="1.125"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.2625 9.025L19.875 3.0625"
        stroke="black"
        stroke-width="1.125"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.4815 11.5C15.7459 11.129 15.8691 10.6758 15.8289 10.2221C15.7888 9.76831 15.5879 9.3438 15.2625 9.025C14.517 8.4655 13.308 8.818 12.5625 9.8125C12.1868 10.2933 11.9902 10.89 12.0068 11.5"
        stroke="black"
        stroke-width="1.125"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_9339_21781">
        <rect
          width="18"
          height="18"
          fill="white"
          transform="translate(3 2.5)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
