<template>
  <input
    class="text-input"
    type="search"
    :value="value"
    :placeholder="placeholder"
    :class="isValid ? '' : 'text-input--invalid'"
  />
</template>

<script>
  export default defineNuxtComponent({
    name: 'TextInput',

    props: {
      isValid: {
        type: Boolean,
        required: true,
      },
      value: {
        type: String,
        required: true,
      },

      placeholder: {
        type: String,
        default: 'Введите название',
      },
    },
  });
</script>

<style lang="scss" scoped>
  $backgroundColor: rgba(255, 255, 255, 0.6);
  $borderColor: #e7e7e7;
  $placeholderColor: #777474;
  $activeColor: #2962f9;
  $invalidColor: #ed5e42;
  $textColor: black;

  .text-input,
  .text-input::placeholder {
    @include fontNormalText(true);
  }

  .text-input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    outline: none;

    width: calc(100vw - 32px);
    height: 48px;
    padding: 12px 48px 12px 16px;
    margin: 0 0 8px;

    border: 1px solid $borderColor;
    border-radius: 2px;
    background-color: $backgroundColor;

    color: $textColor;

    transition:
      color 0.3s ease,
      border-color 0.3s ease;

    @include big-tablet {
      width: 100%;
    }

    &::placeholder {
      color: $placeholderColor;
    }

    &::-ms-clear {
      display: none;
    }
    &::-ms-reveal {
      display: none;
    }
    &::-webkit-search-decoration {
      display: none;
    }
    &::-webkit-search-cancel-button {
      display: none;
    }
    &::-webkit-search-results-button {
      display: none;
    }
    &::-webkit-search-results-decoration {
      display: none;
    }

    @include hover {
      border-color: $activeColor;
    }

    &:focus {
      border-color: $activeColor;
    }

    &--invalid {
      border-color: $invalidColor !important;
    }
    &--FilterContainer {
      margin-bottom: 8px;
    }
  }
</style>
