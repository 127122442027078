import type { Menu, MenuItem, MenuTime } from './types';
import { capitalize } from '@/utils/capitalize';

export const getSelected = (item: any): boolean | null | undefined => item.selected;

export function prepareTwice(target: any, data: Array<object>): void {
  const number = data.length / 2;
  const listFirst = data.slice(0, Math.ceil(number));
  const listLast = data.slice(Math.floor(number) + 1, data.length);

  target.listFirst = listFirst;
  target.listLast = listLast;
}

export function prepareTwiceDishes(target: any, data: Array<object>): void {
  target.listFirst = data.slice(0, 4);
  target.listLast = data.slice(4);
}
export function prepareData(data: MenuItem[]): MenuItem[] {
  return data.reduce((acc: MenuItem[], item: MenuItem, index: number, state: MenuItem[]) => {
    const prev_id = state[index - 1]?.id;
    const next_id = state[index + 1]?.id;
    acc.push({
      ...item,
      prev_id,
      next_id,
      index,
      isLockAnimation: false,
    });
    return acc;
  }, [] as MenuItem[]);
}

export function mapSelectById<T>(_vm: any, data: T[], id: string, value?: any): T[] {
  return data.map((item: any) => {
    if (item.id === id) {
      item.selected = typeof value !== 'undefined' ? value : !item.selected;
      item.activatedHandler = false;
    }
    return item;
  });
}
export function mapSelectByIdAnimation<T>(data: T[], id: string, value?: any): T[] {
  return data.map((item: any) => {
    if (item.id === id) {
      item.selected = typeof value !== 'undefined' ? value : !item.selected;
      item.activatedHandler = false;
      item.isLockAnimation = false;
    }
    return item;
  });
}
export const currentListTwice = ({
  menuList,
  type,
  row,
}: {
  menuList: Menu[];
  type: string;
  row: string;
}): MenuItem[] | MenuTime[] => {
  // @ts-ignore
  const menuItem = menuList.find((el) => el.name === type);
  const rowName = 'list' + capitalize(row ?? '');
  // @ts-ignore
  return menuItem.openedListTwice[rowName];
};
export function movingTwiceElements(
  vm: any,
  menuList: Menu[],
  type: string,
  id: string,
  row: string,
  animateCb?: Function,
): Promise<any> {
  // @ts-ignore
  const currentList = currentListTwice({ menuList, type, row });
  return movingElements(vm, currentList, id, animateCb);
}

export function movingIngredients(
  vm: any,
  currentList: MenuItem[] | MenuTime[],
  id: string,
  animateCb: Function,
  isCurrenList: boolean,
): Promise<any> {
  if (isCurrenList) {
    return movingElements(vm, currentList, id, animateCb);
  } else {
    const fn = () => {
      // currentList.splice(index, 1);
      // currentList.splice(0, 0, item);
    };
    return animateCb ? animateCb(fn) : fn();
  }
}
export function movingElements(
  _vm: any,
  currentList: MenuItem[] | MenuTime[],
  id: string,
  animateCb?: Function,
): Promise<any> {
  // @ts-ignore
  const item = currentList.find((el) => el.id === id);
  if (!item) {
    return Promise.resolve();
  }

  const index = currentList.indexOf(item);
  if (item.selected) {
    if (index !== 0) {
      const fn = () => {
        currentList.splice(index, 1);
        currentList.splice(0, 0, item);
      };
      return animateCb ? animateCb(fn) : fn();
    }
    return Promise.resolve();
  }

  // @ts-ignore
  const nextItem = currentList.find((el) => el.id === item.next_id);
  if (nextItem && !nextItem.selected) {
    const nextItemIndex = currentList.indexOf(nextItem) - 1;
    if (index !== nextItemIndex) {
      const fn = () => {
        currentList.splice(index, 1);
        currentList.splice(nextItemIndex, 0, item);
      };
      return animateCb ? animateCb(fn) : fn();
    }
    return Promise.resolve();
  }

  // @ts-ignore
  const prevItem = currentList.find((el) => el.id === item.prev_id);
  if (prevItem && !prevItem.selected) {
    const prevItemIndex = currentList.indexOf(prevItem);
    if (index !== prevItemIndex) {
      const fn = () => {
        currentList.splice(index, 1);
        currentList.splice(prevItemIndex, 0, item);
      };
      return animateCb ? animateCb(fn) : fn();
    }
    return Promise.resolve();
  }
  if (index !== item.index + 1) {
    const fn = () => {
      currentList.splice(index, 1);
      currentList.splice(item.index + 1, 0, item);
    };
    return animateCb ? animateCb(fn) : fn();
  }
  return Promise.resolve();
}

export function movingFindElements(
  _vm: any,
  currentList: Array<any>,
  id: string,
  _selectedLength: number,
): void {
  // @ts-ignore
  const item = currentList.find((el) => el.id === id);

  if (!item) {
    return;
  }

  const index = currentList.indexOf(item);

  if (item?.selected) {
    currentList.splice(index, 1);

    const currentListAlphabetic = [
      ...currentList.filter((item: MenuItem) => item.selected),
      ...[...currentList.filter((item: MenuItem) => !item.selected), item].sort(
        sortMenuItemAlphabetic(),
      ),
    ];

    const indexByAlphabetic = currentListAlphabetic.indexOf(item);

    currentList.splice(indexByAlphabetic, 0, item);
  } else if (index > 0) {
    currentList.splice(index, 1);
    currentList.splice(0, 0, item);
  }
  item.selected = !item.selected;
  item.selectedTimestamp = item.selected ? new Date().valueOf() : null;
  item.activatedHandler = false;
}

export const resetSelected = (item: MenuItem | MenuTime) => {
  item.selected = item.id === 'any';
  item.activatedHandler = false;
  return item;
};

export const mapSelectedCb = (selected: boolean) => (item: any) => ({
  ...item,
  selected,
});

export const mapSelectedAnimationCb = (selected: boolean) => (item: any) => ({
  ...item,
  selected,
  isLockAnimation: false,
});

export function getPrefix(type: string): string {
  if (type === 'selectedIncludedIngredients') {
    return 's-ingredientom/';
  }
  if (type === 'selectedExcludedIngredients') {
    return 'bez-ingredienta/';
  }
  return '';
}

export function handleTimeInputChange(vm: any, id: string) {
  const item: object = vm.time.find((item: any) => item.id === id);
  const index = vm.time.indexOf(item);

  return vm.time.map((currentItem: object, currentIndex: number) => {
    // @ts-ignore
    if (item.selected) {
      return {
        ...currentItem,
        selected: !(currentIndex >= index),
        isLockAnimation: false,
        activatedHandler: false,
      };
    }
    return {
      ...currentItem,
      selected: currentIndex <= index,
      isLockAnimation: false,
      activatedHandler: false,
    };
  });
}

export function handleIncludedIngredientsChange(vm: any, id: string, animate: boolean = true) {
  const itemSelected = vm.selectedIncludedIngredients.find((item: any) => item.id === id);
  const itemFound = vm.foundIncludedIngredients.find((item: any) => item.id === id);
  if (itemSelected) {
    vm.selectedIncludedIngredients = vm.selectedIncludedIngredients.filter(
      (item: any) => item.id !== id,
    );
  } else {
    vm.selectedIncludedIngredients.unshift({ ...itemFound, selected: true });
  }
  animate
    ? movingFindElements(vm, vm.foundIncludedIngredients, id, vm.selectedIncludedIngredients.length)
    : mapSelectById(vm, vm.foundIncludedIngredients, id);
}
export function handleExcludedIngredientsChange(vm: any, id: string, animate: boolean = true) {
  const itemSelected = vm.selectedExcludedIngredients.find((item: any) => item.id === id);
  const itemFound = vm.foundExcludedIngredients.find((item: any) => item.id === id);
  if (itemSelected) {
    // @ts-ignore
    vm.selectedExcludedIngredients = vm.selectedExcludedIngredients.filter(
      (item: any) => item.id !== id,
    );
  } else {
    // @ts-ignore
    vm.selectedExcludedIngredients.unshift({ ...itemFound, selected: true });
  }
  // @ts-ignore
  animate
    ? movingFindElements(vm, vm.foundExcludedIngredients, id, vm.selectedExcludedIngredients.length)
    : mapSelectById(vm, vm.foundExcludedIngredients, id);
}

export function handleCuisines(vm: any, id: string, animate: boolean = true) {
  const itemSelected = vm.selectedCuisines.find((item: any) => item.id === id);
  const itemFound = vm.foundCuisines.find((item: any) => item.id === id);

  if (itemSelected) {
    // @ts-ignore
    vm.selectedCuisines = vm.selectedCuisines.filter((item: any) => item.id !== id);
  } else {
    // @ts-ignore
    vm.selectedCuisines.unshift({
      ...itemFound,
      selected: true,
      selectedTimestamp: new Date().valueOf(),
    });
  }

  // @ts-ignore
  animate
    ? movingFindElements(
        vm,
        itemFound ? vm.foundCuisines : vm.selectedCuisines,
        id,
        vm.selectedCuisines.length,
      )
    : mapSelectById(vm, vm.foundCuisines, id);
}

export function sortedbyAlphabet(list: MenuItem[]): MenuItem[] {
  const collator = new Intl.Collator('ru-RU');
  return list.sort((a, b) => collator.compare(a.title, b.title));
}

export function create_UUID(): string {
  let dt = new Date().getTime();
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);

    return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
}

export function sortMenuItemAlphabetic(): (a: any, b: any) => number {
  return (a: MenuItem, b: MenuItem) => {
    const collator = new Intl.Collator('ru-RU');
    return collator.compare(a.title, b.title);
  };
}

export function sortMenuItem(): (a: any, b: any) => number {
  return (a: MenuItem, b: MenuItem) => {
    if (a.selected && b.selected) {
      return (b.selectedTimestamp ?? 0) - (a.selectedTimestamp ?? 0);
    }

    if (a.selected || b.selected) {
      const result = Number(b.selected ?? 0) - Number(a.selected ?? 0);
      return result;
    }

    const collator = new Intl.Collator('ru-RU');
    return collator.compare(a.title, b.title);
  };
}
