<script setup lang="ts"></script>

<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.67188 4.06641C5.36121 4.06641 5.10938 4.31825 5.10938 4.62891V8.28516H3.5625C3.25184 8.28516 3 8.537 3 8.84766V13.0664C3 13.3771 3.25184 13.6289 3.5625 13.6289H7.78125H12C12.3107 13.6289 12.5625 13.3771 12.5625 13.0664V8.84766C12.5625 8.537 12.3107 8.28516 12 8.28516H10.4531V4.62891C10.4531 4.31825 10.2013 4.06641 9.89062 4.06641H5.67188ZM7.78125 8.28516H6.23438V5.19141H9.32812V8.28516H7.78125ZM8.34375 12.5039V9.41016H9.89062H11.4375V12.5039H8.34375ZM7.21875 9.41016V12.5039H4.125V9.41016H5.67188H7.21875ZM19.8281 14.6133H4.32511C4.14124 14.613 3.95952 14.6529 3.7926 14.73C3.62552 14.8072 3.47729 14.9199 3.35824 15.0603C3.23919 15.2007 3.15219 15.3653 3.10331 15.5428C3.05533 15.717 3.04521 15.8994 3.07357 16.0777C3.22892 17.2214 3.78368 18.2734 4.63988 19.0476C5.49807 19.8236 6.60356 20.2703 7.75995 20.3083C7.76945 20.3086 7.77896 20.3087 7.78847 20.3085C8.6877 20.2925 9.56394 20.0217 10.3155 19.5276C10.9638 19.1014 11.4964 18.5241 11.8688 17.8477H19.8281C20.257 17.8477 20.6684 17.6773 20.9716 17.374C21.2749 17.0707 21.4453 16.6594 21.4453 16.2305C21.4453 15.8016 21.2749 15.3902 20.9716 15.0869C20.6684 14.7837 20.257 14.6133 19.8281 14.6133ZM4.2645 15.7513C4.28306 15.7427 4.30327 15.7382 4.32373 15.7383L19.8281 15.7383C19.9587 15.7383 20.0838 15.7901 20.1762 15.8824C20.2685 15.9747 20.3203 16.0999 20.3203 16.2305C20.3203 16.361 20.2685 16.4862 20.1762 16.5785C20.0838 16.6708 19.9587 16.7227 19.8281 16.7227H11.5219C11.3011 16.7227 11.1007 16.8518 11.0095 17.053C10.7261 17.6783 10.2712 18.2104 9.69746 18.5876C9.12773 18.9621 8.46411 19.1686 7.78264 19.1834C6.89738 19.1509 6.05171 18.8075 5.39442 18.2131C4.73359 17.6156 4.30597 16.8031 4.1875 15.9201L4.18479 15.9021C4.18141 15.8819 4.18248 15.8613 4.18791 15.8416C4.19334 15.8218 4.20301 15.8035 4.21624 15.788C4.22946 15.7724 4.24593 15.7598 4.2645 15.7513Z"
      fill="black"
    />
  </svg>
</template>
