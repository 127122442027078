import { getSelected } from '~/containers/FilterContainer/pureFunctions';
import type {
  MenuItem,
  SelectedMenuItem,
  FetchIngredientsPayload,
  MenuValues,
  FilterAnimate,
} from '~/containers/FilterContainer/types';
import { defineStore } from 'pinia';
import { wrapPiniaStore } from '@devhacker/shared/utils/wrapPiniaStore';

export const useFilterStore = wrapPiniaStore(
  defineStore('filter', {
    state: () => ({
      dishes: [] as MenuItem[],
      recipesCount: 0,
      isAnimate: true,
      isFixedSubmitButton: true,
      openedMenu: '' as MenuValues | '',
      animate: {
        dishes: true,
        cuisines: true,
        includedIngredients: true,
        excludedIngredients: true,
      } as FilterAnimate,
      sortDirection: 'asc' as 'asc' | 'desc',
      dishesTwice: false,
    }),

    getters: {
      selectedDishes: (state) => state.dishes.filter(getSelected) as SelectedMenuItem[],
    },

    actions: {
      async fetchFilterSettings(path: string): Promise<any> {
        try {
          const { $wordpressApi } = useNuxtApp();
          const { data } = await $wordpressApi.fetchFilterSettings(path);
          return data;
        } catch (error) {
          console.error(error);
          return null;
        }
      },

      async fetchRecipesCount(url: string) {
        try {
          const { $wordpressApi } = useNuxtApp();
          const {
            data: { count },
          } = await $wordpressApi.fetchRecipesCount(url);
          this.recipesCount = count || 0;
        } catch (error: any) {
          if (error.response.status === 404) {
            this.recipesCount = 0;
          }
          console.error('fetch recipes count error: ', error);
        }
      },

      async fetchMenus(): Promise<any> {
        try {
          const { $wordpressApi } = useNuxtApp();
          const { data } = await $wordpressApi.fetchFilterMenus();
          return data;
        } catch (error) {
          console.error(error);
          return null;
        }
      },

      async fetchIngredients({ searchValue, type }: FetchIngredientsPayload): Promise<any> {
        try {
          const { $wordpressApi } = useNuxtApp();
          const {
            data: { items },
          } = await $wordpressApi.searchIngredients(searchValue);
          return { items: items || [], type };
        } catch (error) {
          console.error(error);
        }
      },

      actionHandlerPhone() {
        this.setAnimate('dishes', true);
        this.setAnimate('cuisines', true);
        this.setAnimate('includedIngredients', true);
        this.setAnimate('excludedIngredients', true);
        this.dishesTwice = true;
        this.sortDirection = 'asc';
      },

      actionHandlerTablet() {
        this.setAnimate('dishes', true);
        this.setAnimate('cuisines', true);
        this.setAnimate('includedIngredients', true);
        this.setAnimate('excludedIngredients', true);
        this.dishesTwice = false;
        this.sortDirection = 'asc';
      },

      actionHandlerDesktop() {
        this.setAnimate('dishes', true);
        this.setAnimate('cuisines', false);
        this.setAnimate('includedIngredients', false);
        this.setAnimate('excludedIngredients', false);
        this.dishesTwice = false;
        this.sortDirection = 'desc';
      },

      setAnimate(type: keyof FilterAnimate, value: boolean) {
        this.animate[type] = value;
      },

      setOpenedMenu(menu: MenuValues | '') {
        this.openedMenu = menu;
      },

      setIsFixedSubmitButton(value: boolean) {
        this.isFixedSubmitButton = value;
      },

      setRecipesCount(count: number) {
        this.recipesCount = count;
      },
    },
  }),
);
