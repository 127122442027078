<template>
  <button
    class="submit-button"
    type="button"
    :class="{ 'submit-button_active': count }"
  >
    Подобрать рецепт {{ count }}
  </button>
</template>

<script>
  export default {
    name: 'FilterSubmitButton',

    props: {
      count: {
        type: String,
        default: '',
      },
    },
  };
</script>

<style lang="scss" scoped>
  $inActiveColor: #d4d7de;
  $activeColor: #ed5e42;
  $textColor: white;

  .submit-button {
    font-family: $mainFont;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;

    width: 100%;
    height: 48px;
    min-height: 48px;

    background-color: $inActiveColor;
    border-radius: 2px;
    border: none;

    color: $textColor;
    transition: background-color 0.2s ease;

    @include big-tablet {
      font-weight: 700;
    }
    &_active {
      background-color: $activeColor;
      cursor: pointer;

      @include big-tablet {
        text-align: left;
        padding-left: 30px;
        @include hover {
          background-color: #d44b3f;
        }
      }
    }
  }
</style>
