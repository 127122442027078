// import { eventBus } from '~/containers/FilterContainer/eventBus';

type TypeAdd = { fn: Function; args: Array<any> };

export class TaskRunner {
  queu: any[] = [];
  status: 'do' | 'wait' = 'wait';
  add({ fn, args }: TypeAdd) {
    if (this.findById(args[2])) {
      return;
    }
    this.queu.unshift({ fn, args });
    // console.log('add');
    this.execute();
  }

  findById(id: string) {
    return this.queu.find((el) => {
      return el.args[2] === id;
    });
  }

  setStatus(value: 'do' | 'wait') {
    this.status = value;
  }

  async execute() {
    if (!this.queu.length) {
      return;
    }
    while (!!this.queu.length && this.status === 'wait') {
      this.setStatus('do');
      // console.log('start task');
      const [{ fn, args }] = this.queu.slice(-1);

      await fn(...args);
      this.queu.pop();
      // console.log('finish task');
      this.setStatus('wait');
      if (!this.queu.length) {
        // eventBus.$emit('taskRunner:finish');
      }
    }
  }
}
