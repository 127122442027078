<script setup lang="ts"></script>

<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_9339_21838)">
      <path
        d="M12 20.4375C16.6599 20.4375 20.4375 16.6599 20.4375 12C20.4375 7.3401 16.6599 3.5625 12 3.5625C7.3401 3.5625 3.5625 7.3401 3.5625 12C3.5625 16.6599 7.3401 20.4375 12 20.4375Z"
        stroke="black"
        stroke-width="1.125"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.75 18.6L7.5 14.8125H8.31C8.48106 14.8131 8.64994 14.7741 8.80346 14.6987C8.95698 14.6232 9.09098 14.5133 9.195 14.3775C9.30215 14.2448 9.37636 14.0887 9.41155 13.9218C9.44675 13.755 9.44193 13.5821 9.3975 13.4175L8.835 11.1675C8.77605 10.9236 8.63672 10.7066 8.43945 10.5515C8.24218 10.3964 7.99844 10.3122 7.7475 10.3125H3.75"
        stroke="black"
        stroke-width="1.125"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.75 6.9375H15.69C15.439 6.93722 15.1953 7.0214 14.998 7.1765C14.8008 7.3316 14.6614 7.54858 14.6025 7.7925L14.04 10.0425C13.9966 10.2077 13.993 10.3809 14.0295 10.5478C14.066 10.7147 14.1415 10.8705 14.25 11.0025C14.354 11.1383 14.488 11.2482 14.6415 11.3237C14.795 11.3991 14.9639 11.4381 15.135 11.4375H16.32L16.905 15C16.9489 15.2597 17.0825 15.4958 17.2826 15.6672C17.4827 15.8385 17.7366 15.9342 18 15.9375H19.5"
        stroke="black"
        stroke-width="1.125"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_9339_21838">
        <rect
          width="18"
          height="18"
          fill="white"
          transform="translate(3 3)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
