<script setup lang="ts"></script>

<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_9339_21914)">
      <path
        d="M12 20.44C16.6613 20.44 20.44 16.6613 20.44 12C20.44 7.33871 16.6613 3.56 12 3.56C7.33871 3.56 3.56 7.33871 3.56 12C3.56 16.6613 7.33871 20.44 12 20.44Z"
        stroke="black"
        stroke-width="1.125"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12 13.125C12.6213 13.125 13.125 12.6213 13.125 12C13.125 11.3787 12.6213 10.875 12 10.875C11.3787 10.875 10.875 11.3787 10.875 12C10.875 12.6213 11.3787 13.125 12 13.125Z"
        stroke="black"
        stroke-width="1.125"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12 10.875V6.9375"
        stroke="black"
        stroke-width="1.125"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.7958 12.7958L14.8125 14.8125"
        stroke="black"
        stroke-width="1.125"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_9339_21914">
        <rect
          width="18"
          height="18"
          fill="white"
          transform="translate(3 3)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
