<script setup lang="ts"></script>

<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_9339_21890)">
      <path
        d="M6.75 19.2188V19.875"
        stroke="black"
        stroke-width="1.125"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.375 19.875V17.9062"
        stroke="black"
        stroke-width="1.125"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12 19.2188V19.875"
        stroke="black"
        stroke-width="1.125"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.25 19.2188V19.875"
        stroke="black"
        stroke-width="1.125"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.625 19.875V17.9062"
        stroke="black"
        stroke-width="1.125"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.4375 21.8438H18.5625"
        stroke="black"
        stroke-width="1.125"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.75 5.65625H17.25V14.4062C17.25 14.6383 17.1578 14.8609 16.9937 15.025C16.8296 15.1891 16.6071 15.2812 16.375 15.2812H7.625C7.39294 15.2812 7.17038 15.1891 7.00628 15.025C6.84219 14.8609 6.75 14.6383 6.75 14.4062V5.65625Z"
        stroke="black"
        stroke-width="1.125"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.4375 5.65625H18.5625"
        stroke="black"
        stroke-width="1.125"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.125 8.28125H6.75"
        stroke="black"
        stroke-width="1.125"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M19.875 8.28125H17.25"
        stroke="black"
        stroke-width="1.125"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.375 5.65625V4.78125C9.375 4.08506 9.65156 3.41738 10.1438 2.92509C10.6361 2.43281 11.3038 2.15625 12 2.15625C12.6962 2.15625 13.3639 2.43281 13.8562 2.92509C14.3484 3.41738 14.625 4.08506 14.625 4.78125V5.65625"
        stroke="black"
        stroke-width="1.125"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_9339_21890">
        <rect
          width="21"
          height="21"
          fill="white"
          transform="translate(1.5 1.5)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
