<script setup lang="ts"></script>

<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_9339_21821)">
      <path
        d="M5.8125 11.685C5.8125 9.89479 6.52366 8.1779 7.78953 6.91203C9.0554 5.64616 10.7723 4.935 12.5625 4.935C14.3527 4.935 16.0696 5.64616 17.3355 6.91203C18.6013 8.1779 19.3125 9.89479 19.3125 11.685H5.8125Z"
        stroke="black"
        stroke-width="1.125"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.6875 11.685H20.4375"
        stroke="black"
        stroke-width="1.125"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.5625 3.81V4.935"
        stroke="black"
        stroke-width="1.125"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.5625 19.56L6.375 16.815L10.3125 20.19L19.3575 13.6575C19.4834 13.5742 19.5856 13.4599 19.6544 13.3256C19.7232 13.1912 19.7562 13.0414 19.7501 12.8906C19.7441 12.7398 19.6993 12.5931 19.62 12.4647C19.5407 12.3363 19.4296 12.2305 19.2975 12.1575V12.1575C18.7891 11.8533 18.2095 11.6882 17.617 11.679C17.0246 11.6698 16.4402 11.8167 15.9225 12.105L13.725 14.1075C12.9792 14.5259 12.1011 14.6414 11.2725 14.43C10.6575 14.28 10.5225 13.995 10.0275 13.53L9.21 12.3375C8.87608 12.0043 8.44588 11.7845 7.98022 11.7091C7.51455 11.6338 7.03698 11.7066 6.615 11.9175V11.9175C6.52477 11.9637 6.44669 12.0305 6.38709 12.1125C6.3275 12.1945 6.28806 12.2893 6.27199 12.3894C6.25592 12.4895 6.26366 12.592 6.29459 12.6885C6.32552 12.785 6.37876 12.8729 6.45 12.945L6.795 13.29C7.18624 13.6849 7.47487 14.1695 7.63568 14.7016C7.79648 15.2337 7.82457 15.7971 7.7175 16.3425L7.44 17.7225"
        stroke="black"
        stroke-width="1.125"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_9339_21821">
        <rect
          width="18"
          height="18"
          fill="white"
          transform="translate(3 3)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
