<template lang="pug">
fieldset.drop-down__container(
  :class="[!isOpen ? 'drop-down__closed' : 'drop-down__opened']"
)
  button.drop-down__top.filter__top(
    @click="$emit('click')"
  )
    .drop-down__header
      span.drop-down__icon.drop-down__icon-title(
        :class="'drop-down__icon-'+ type"
      )
        component(:is="componentName")
      legend.drop-down__title {{ title }}
    span.drop-down__icon.drop-down__icon--arrow(
      v-if="showIcon",
      :class="{'drop-down__icon_opened': isOpen}"
    )
      include icons/arrow.svg

  // TODO
  // дублирование кода: блоки с классами
  // drop-down__closed и drop-down__opened лишние
  // сюда можно передавать список, который нужно показывать
  // вместо слотов selectedList и openedList - оставить слот default
  // показ слота search контролировать из родителя
  .drop-down
    component(
      :is="listContainer"
      class="drop-down__list "
      :class="!isOpen ? 'drop-down__selected-list' : 'drop-down__all-list'"
    )
      slot()
</template>

<script lang="ts">
  import type { PropType } from 'vue';

  // @ts-ignore
  // import { isMobile } from 'mobile-device-detect';
  import ScrollContainer from '@/components/lh-ui/ScrollContainer/ScrollContainer.vue';

  import IconCookingMethods from '~/components/filter/DropDownList/icons/IconCookingMethods.vue';
  import IconDishes from '~/components/filter/DropDownList/icons/IconDishes.vue';
  import IconMenus from '~/components/filter/DropDownList/icons/IconMenus.vue';
  import IconCuisines from '~/components/filter/DropDownList/icons/IconCuisines.vue';
  import IconIncludedIngredients from '~/components/filter/DropDownList/icons/IconIncludedIngredients.vue';
  import IconExcludedIngredients from '~/components/filter/DropDownList/icons/IconExcludedIngredients.vue';
  import IconTime from '~/components/filter/DropDownList/icons/IconTime.vue';
  import { capitalize } from '~/utils/capitalize';

  export default defineNuxtComponent({
    name: 'DropDownList',

    scrollContainerOptions: {
      wheelPropagation: false,
    },

    components: {
      IconTime,
      IconExcludedIngredients,
      IconIncludedIngredients,
      IconCuisines,
      IconMenus,
      IconDishes,
      IconCookingMethods,
      ScrollContainer,
    },

    emits: ['click'],

    inject: {
      media: {
        default() {
          return () => null;
        },
      },
    },

    props: {
      type: {
        type: String as PropType<string>,
        required: true,
      },
      title: {
        type: String as PropType<string>,
        required: true,
      },

      isOpen: {
        type: Boolean as PropType<boolean>,
        required: true,
      },
      showIcon: {
        type: Boolean as PropType<boolean>,
        required: true,
      },
    },

    computed: {
      mediaQuery(): 'mobile' | 'tablet' | 'desktop' | null {
        // @ts-ignore
        if (this.media) {
          // @ts-ignore
          return this.media();
        }

        return null;
      },

      // isMobile (): boolean {
      //   return isMobile;
      // },

      listContainer() {
        // return !isMobile ? ScrollContainer : 'div';
        // @ts-ignore
        return this.mediaQuery === 'desktop' ? ScrollContainer : 'div';
      },

      componentName(): string {
        // @ts-ignore
        return 'Icon' + capitalize(this.type);
      },
    },
  });
</script>
<style lang="scss">
  @include big-tablet {
    // .drop-down__opened .drop-down__icon-title {
    //   &.drop-down__icon-includedIngredients {
    //     & > svg path {
    //       fill: #2962F9;
    //       stroke: none;
    //     }
    //   }

    //   & > svg path {
    //     stroke: #2962F9;
    //   }
    // }
    .drop-down__top {
      &:hover .drop-down__icon-title {
        &.drop-down__icon-includedIngredients {
          & > svg path {
            fill: #2962f9;
            stroke: none;
          }
        }
        & > svg path {
          stroke: #2962f9;
        }
      }
    }
  }
</style>
<style lang="scss" scoped>
  $textColor: black;
  $borderColor: #e7e7e7;
  .drop-down {
    @include big-tablet {
      padding-left: 16px;
    }
    &__container {
      border: none;
      position: relative;

      @include big-tablet {
        &:after {
          position: absolute;
          content: '';

          width: calc(100% - 32px);
          height: 1px;
          bottom: 0;
          left: 16px;

          background-color: $borderColor;
        }
      }
      &:last-of-type {
        &:after {
          display: none;
        }
      }
    }

    &__top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 16px;
      border: none;
      background-color: unset;
      cursor: pointer;
      @include big-tablet {
        padding: 16px 15px 16px 16px;
      }
    }

    &__title {
      font-family: $secondFont;
      font-style: normal;
      font-weight: 600;
      color: black;
      font-size: 16px;
      line-height: 24px;
      margin-right: 10px;
      text-align: left;
      user-select: none;
      @include big-tablet {
        font-weight: 500;
        font-size: 15px;
      }
    }

    &__icon {
      display: block;
      &-title {
        svg {
          width: 32px;
          height: 32px;
          display: block;
          @include big-tablet {
            width: 24px;
            height: 24px;
          }
        }
      }
      &--arrow {
        svg {
          transform: rotate(180deg);
        }
      }
      &_opened {
        margin-top: 8px;
        svg {
          transform: rotate(0deg);
        }
      }
    }

    &__list {
      @include padding-y(0);
      max-height: 322px;
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      @include big-tablet {
        max-height: 344px;
        align-content: flex-start;
        padding-right: 15px;
      }
    }

    // &__selected-list:has(.input__label) {
    //   padding-bottom: 24px;
    // }

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 8px;
    }

    &__opened {
      // .drop-down {
      //   &__top {
      //     padding-bottom: 12px;
      //   }
      // }
      @include big-tablet {
        .drop-down {
          padding-bottom: 24px;
        }
      }
    }
  }
</style>
