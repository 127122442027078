import { ref, onMounted, onBeforeUnmount } from 'vue';
import { isMobileOnly } from 'mobile-device-detect';

export function useBeforePrint() {
  const banners = ref<{ parentElement: HTMLElement; display?: string }[]>([]);

  const onBeforePrint = () => {
    const adfoxBannersSelector = 'body > div > a[href*="adfox"]';

    banners.value = Array.from(document.querySelectorAll(adfoxBannersSelector)).map((banner) => {
      return {
        parentElement: banner.parentElement as HTMLElement,
        display: banner.parentElement?.style.display,
      };
    });

    banners.value.forEach((banner) => {
      banner.parentElement.style.display = 'none';
    });
  };

  const onAfterPrint = () => {
    banners.value.forEach((banner) => {
      banner.parentElement.style.display = banner.display ?? '';
    });
  };

  onMounted(() => {
    if (!isMobileOnly) {
      window.addEventListener('beforeprint', onBeforePrint);
      window.addEventListener('afterprint', onAfterPrint);
    }
  });

  onBeforeUnmount(() => {
    window.removeEventListener('beforeprint', onBeforePrint);
    window.removeEventListener('afterprint', onAfterPrint);
  });
}
