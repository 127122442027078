<template>
  <div
    class="recipes-layout"
    :class="{ 'route-preview': isPreview }"
    sync-adfox-params
    typo-form-enabled
  >
    <!-- зона виджетов "баннер над меню" -->

    <div class="recipes-layout__widgets-above-header">
      <WidgetsRendererContainer
        :widgets="widgetsAboveHeader"
        widget-class-name="page__widget-above-header"
      />
    </div>

    <!-- нужно для того чтобы хеадер не перекрывал фильтры в мобильной версии -->
    <TopHeaderContainer />

    <!-- Navigation Menu -->
    <NavMenuContainer class="main" />
    <PrimaryMenu
      :primary-nav-menu-items="primaryNavMenuItems"
      location="mobile"
      type="secondary"
    />
    <NavMenuBelowHeaderContainer />

    <!-- брендирование рендерится внутрь этого контейнера -->
    <ContentWrapperForBranding class="recipes-layout__branding" />

    <!-- зона виджетов "Баннер под меню" -->
    <div
      v-if="widgetsBelowHeader.length"
      class="recipes-layout__widgets-below-header"
    >
      <WidgetsRendererContainer
        :widgets="widgetsBelowHeader"
        widget-class-name="widget-below-header"
      />
    </div>

    <div
      id="recipes-layout-content"
      class="recipes-layout__content"
      :class="recipesLayoutContentClass"
    >
      <!-- контент должен быть выше сайдбаров для SEO -->
      <div
        id="main"
        class="recipes-layout__main recipes__container"
      >
        <slot />
      </div>

      <aside
        id="filters"
        ref="filter"
        class="recipes-layout__filter-wrapper"
        :class="{ 'recipes-layout__filter-wrapper_open': openedFilter }"
      >
        <div class="recipes-layout__filter-wrapper-inner">
          <div
            ref="shutter"
            class="recipes-layout__shutter"
          ></div>
          <FilterContainer
            class="recipes-layout__filter"
            :class="{ filter_opened: openedFilter }"
            @update-url="setOpenedFilter(false)"
            @used-filter="onFilterUsed"
            @filter-count="setCount"
          />
        </div>
      </aside>

      <Transition name="filters__paranja">
        <div
          v-if="openedFilter"
          class="filters__paranja"
        />
      </Transition>

      <!-- название класса контейнера используется внутри WidgetsRendererContainer -->
      <aside class="recipes-layout__right-sidebar">
        <WidgetsRendererContainer
          class="recipes-layout__right-sidebar-widget-list"
          :widgets="rightSidebarWidgets"
          :in-sidebar="true"
        />
      </aside>
    </div>

    <!-- Footer -->
    <TheFooterContainer />

    <!-- Уведомления, окно авторизации, форма об опечатке, форма подписки на уведомления в сафари -->
    <ClientOnly>
      <div
        class="recipes-layout__filter-button"
        :class="filterButtonClass"
      >
        <!-- TODO: лишний функциональный компонент -->
        <!-- или сделать полноценный или вынести в текущий -->
        <FilterOpenButton
          v-if="isShowFilterButton"
          :counter="filterCount"
          :class-counter-more="classCounterMore"
          :counter-text="counterText"
          @button-click="setOpenedFilter(true)"
        />
      </div>
    </ClientOnly>
  </div>
</template>

<script lang="ts">
  import TopHeaderContainer from '~/containers/TopHeaderContainer/TopHeaderContainer.vue';
  import TheFooterContainer from '~/containers/TheFooterContainer.amp.vue';
  import NavMenuContainer from '~/containers/NavMenuContainer/NavMenuContainer.vue';
  import PrimaryMenu from '~/components/PrimaryMenu.vue';
  import NavMenuBelowHeaderContainer from '~/containers/NavMenuBelowHeaderContainer.vue';
  import WidgetsRendererContainer from '~/containers/WidgetsRendererContainer.vue';
  import ContentWrapperForBranding from '~/components/ContentWrapperForBranding.vue';
  import FilterContainer from '~/containers/FilterContainer/FilterContainer.vue';
  import FilterOpenButton from '~/components/filter/FilterOpenButton/FilterOpenButton.vue';

  import { useMenusStore } from '~/store/menus';
  import { isTheRecipePage, isRecipePreviewPage } from '~/utils/router';
  import PageScroll from '~/libs/PageScroll';
  import { useWidgetsStore } from '~/store/widgets';
  import { useBeforePrint } from '@/composables/useBeforePrint';
  import { useSmartBanner } from '@/composables/useSmartBanner';

  export default defineNuxtComponent({
    name: 'RecipesLayout',

    components: {
      TopHeaderContainer,
      TheFooterContainer,
      NavMenuContainer,
      PrimaryMenu,
      NavMenuBelowHeaderContainer,
      WidgetsRendererContainer,
      ContentWrapperForBranding,
      FilterContainer,
      FilterOpenButton,
    },

    setup() {
      useBeforePrint();
      const { isSmartBanner } = useSmartBanner();

      return {
        isSmartBanner,
      };
    },

    data() {
      return {
        openedFilter: false as boolean,
        // analytics
        filterUsedEventForCurrentPageSent: false as boolean,
        // See https://app.clickup.com/t/18mthq3
        scrollDown: false as boolean,

        filterCount: 0 as number,
      };
    },

    computed: {
      filterButtonClass(): Record<string, boolean> {
        return {
          // @ts-ignore
          'recipes-layout__filter-button--smart-banner': this.isSmartBanner,
        };
      },
      recipesLayoutContentClass(): Record<string, boolean> {
        return {
          'recipes-layout__content--up': this.openedFilter,
        };
      },
      primaryNavMenuItems(): Array<MenuItem> {
        return useMenusStore().menus.projects?.items ?? [];
      },
      widgetsAboveHeader(): WidgetInRecipes[] {
        return useWidgetsStore().currentAboveRecipeHeader;
      },
      widgetsBelowHeader(): WidgetInRecipes[] {
        return useWidgetsStore().currentBelowRecipeHeader;
      },
      isShowFilterButton(): boolean {
        // return !this.openedFilter;
        return !this.openedFilter && !this.scrollDown;
      },
      rightSidebarWidgets(): WidgetInRecipes[] {
        // на внутренней странице рецепта
        if (isTheRecipePage(useRoute().name)) {
          return useWidgetsStore().currentRecipeSidebar;
        }
        // на архивной странице
        return useWidgetsStore().currentRecipesSidebar;
      },
      isPreview(): boolean {
        return isRecipePreviewPage(useRoute().name);
      },
      classCounterMore(): string {
        return this.filterCount > 9 ? 'counter--more' : '';
      },
      counterText(): string {
        return this.filterCount > 9 ? '9+' : this.filterCount + '';
      },
    },

    watch: {
      $route(): void {
        this.filterUsedEventForCurrentPageSent = false;
      },
    },

    mounted(): void {
      this.$nextTick(() => {
        if (!(this.$refs.shutter && this.$refs.filter)) {
          return;
        }
        this.touchCloseHandler(this.$refs.shutter as HTMLElement, this.$refs.filter as HTMLElement);

        document.addEventListener('click', (e) => {
          // @ts-expect-error No refs types in options
          const withinBoundaries = e.composedPath().includes(this.$refs.filter);

          if (!withinBoundaries && this.openedFilter) {
            this.setOpenedFilter(false);
          }
        });
      });
    },

    methods: {
      touchCloseHandler(handlerElement: HTMLElement, moveElement: HTMLElement) {
        let startY: number;
        let rafId: number;
        let deltaY: number = 0;
        let moving = false;

        const pointerDown = ({ clientY, isPrimary }: PointerEvent) => {
          if (!isPrimary) {
            return;
          }
          startY = clientY;
          deltaY = 0;
          moveElement.style.transition = '';
          handlerElement.addEventListener('pointermove', pointerMove);
          handlerElement.addEventListener('pointerup', pointerUp);
          handlerElement.addEventListener('pointercancel', pointerUp);
        };

        const pointerMove = ({ clientY, isPrimary }: PointerEvent) => {
          if (!isPrimary) {
            return;
          }
          deltaY = Math.max(clientY - startY, 0);
          if (!moving) {
            moving = true;
            rafId = requestAnimationFrame(() => {
              moving = false;
              moveElement.style.transform = `translateY(${deltaY}px)`;
            });
          }
        };

        const pointerUp = ({ isPrimary }: PointerEvent) => {
          if (!isPrimary) {
            return;
          }
          moving = false;
          handlerElement.removeEventListener('pointermove', pointerMove);
          handlerElement.removeEventListener('pointerup', pointerUp);
          handlerElement.removeEventListener('pointercancel', pointerUp);
          cancelAnimationFrame(rafId);
          requestAnimationFrame(() => {
            moveElement.style.transform = '';
            moveElement.style.transition = 'transform 250ms';
            if (deltaY > 100) {
              this.setOpenedFilter(false);
            }
          });
        };

        handlerElement.addEventListener('pointerdown', pointerDown);
      },
      setOpenedFilter(val: boolean): void {
        this.openedFilter = val;
        this.onFilterUsed();

        if (val) {
          PageScroll.disable();
        } else {
          const filterEl = document.querySelector('#filters');
          const callbackfn = (el: Element) => {
            el.scrollLeft = 0;
          };
          filterEl?.querySelectorAll('.container__first').forEach(callbackfn);
          filterEl?.querySelectorAll('.container__last').forEach(callbackfn);
          filterEl?.querySelectorAll('.container__once').forEach(callbackfn);
          PageScroll.enable();
        }
      },
      onFilterUsed(): void {
        if (this.filterUsedEventForCurrentPageSent) {
          return;
        }
        this.filterUsedEventForCurrentPageSent = true;
      },
      setCount(payload: number) {
        this.filterCount = payload;
      },
    },
  });
</script>

<style lang="scss" scoped>
  $lineColor: #e7e7e7;
  .recipes {
    &__container {
      width: 640px;

      &_error {
        @include tablet {
          margin-bottom: 24px;
        }
      }
    }
  }

  .filters__paranja {
    position: fixed;
    z-index: 98;
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.8;
    transition: opacity 400ms linear;
    background-color: #000000;
    @include big-tablet() {
      display: none;
    }

    &-enter,
    &-leave-to {
      opacity: 0 !important;
    }
    &-enter-to {
      opacity: 0.75 !important;
    }
  }

  .recipes-layout {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background: #e5e5e5;
    &__shutter {
      height: 48px;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      // обязательно для нормальной работы pointer events
      // pan-y pan-down pan-up вызывали баги на android 13
      touch-action: none;

      @include big-tablet {
        display: none;
      }
    }
    &__main {
      order: 2;
      margin-top: 16px;
      @include tablet {
        margin-top: 24px;
      }
    }

    &__branding {
      display: none;
    }

    &__content {
      // чтобы перекрыть рекламу
      // #1p63ea1
      z-index: 3;
      display: flex;
      flex: 1;
      justify-content: center;
      width: 100%;
      background: #fff;

      @include margin-x(auto);

      @include desktop {
        max-width: 1280px;
        justify-content: space-between;
      }

      &--up {
        z-index: 13;
      }
    }

    &__filter-wrapper {
      order: 1;
      display: flex;
      position: fixed;
      z-index: 99;
      width: 100%;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      transform: translateY(150%);
      transition: transform 25ms linear;
      will-change: transform;
      margin-top: 80px;

      @include tablet {
        margin-right: 0;
      }

      @include big-tablet() {
        z-index: 2;
        margin-top: unset;
        top: 48px;
        position: sticky;
        flex: 1 0 260px;
        align-self: flex-start;
        min-width: 268px;
        max-width: 268px;
        margin-right: 24px;
        background: transparent;
        transform: translateY(0);
        height: 100vh;
        padding-bottom: 24px;
        border-right: 1px solid #e7e7e7;
      }

      @include desktop {
        margin-right: 0;
      }

      &_open {
        transform: translateY(0);

        @include big-tablet() {
          transform: translateY(0);
        }
      }
    }

    &__filter-wrapper-inner {
      background-color: white;
      width: 100%;
      padding-top: 48px;
      border-radius: 12px 12px 0 0;

      position: relative;

      &::before {
        position: absolute;
        content: '';
        width: 36px;
        height: 4px;
        background-color: $lineColor;
        top: 23px;
        left: calc(50% - 18px);
        border-radius: 4px;
      }

      @include big-tablet() {
        border-radius: unset;
        height: unset;
        background-color: unset;
        padding-top: 0;

        &::before,
        &::after {
          display: none;
        }
      }
    }

    &__right-sidebar {
      display: none;
      order: 3;

      @include desktop {
        width: 300px;
        display: block;
        margin-right: 24px;
        margin-top: 24px;
      }
    }

    &__right-sidebar-widget-list {
      height: 100%;
    }

    &__filter-button {
      position: fixed;
      right: 16px;
      bottom: 16px;
      z-index: 10;

      @include big-tablet {
        display: none;
      }
      @include print {
        display: none;
      }

      &--smart-banner {
        bottom: calc(16px + 84px);
      }
    }
  }

  // скрыть все элементы кроме nuxt в печатной версии
  .recipes-layout > *:not(.recipes-layout__content),
  .recipes-layout__filter-wrapper,
  .recipes-layout__right-sidebar {
    @include print {
      display: none;
    }
  }
  .recipes-layout__content {
    @include print {
      background: none;
      max-width: unset;
    }
  }
</style>
