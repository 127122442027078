<script setup lang="ts"></script>

<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_9339_21871)">
      <path
        d="M6.375 20.4375C7.9283 20.4375 9.1875 19.1783 9.1875 17.625C9.1875 16.0717 7.9283 14.8125 6.375 14.8125C4.8217 14.8125 3.5625 16.0717 3.5625 17.625C3.5625 19.1783 4.8217 20.4375 6.375 20.4375Z"
        stroke="black"
        stroke-width="1.125"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.375 9.1875C7.9283 9.1875 9.1875 7.9283 9.1875 6.375C9.1875 4.8217 7.9283 3.5625 6.375 3.5625C4.8217 3.5625 3.5625 4.8217 3.5625 6.375C3.5625 7.9283 4.8217 9.1875 6.375 9.1875Z"
        stroke="black"
        stroke-width="1.125"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.747 13.518L5.1615 8.91226"
        stroke="black"
        stroke-width="1.125"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20.4375 5.8125L5.1615 15.087"
        stroke="black"
        stroke-width="1.125"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20.4375 15.9375H19.3125"
        stroke="black"
        stroke-width="1.125"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.0625 15.9375H15.9375"
        stroke="black"
        stroke-width="1.125"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.6875 15.9375H12.5625"
        stroke="black"
        stroke-width="1.125"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_9339_21871">
        <rect
          width="18"
          height="18"
          fill="white"
          transform="translate(3 3)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
