import { ref, computed, onMounted, onBeforeUnmount } from 'vue';
import { isMobileOnly, isIOS } from 'mobile-device-detect';

export function useSmartBanner() {
  const windowInnerHeight = ref(0);

  const isIphone = computed(() => isMobileOnly && isIOS);
  const isSmartBanner = computed(() => {
    if (typeof document === 'undefined' || !isIphone.value) {
      return false;
    }

    return windowInnerHeight.value < document.documentElement?.clientHeight;
  });

  const onResizeWindowInnerHeight = () => {
    windowInnerHeight.value = window.innerHeight;
  };

  onMounted(() => {
    if (isIphone.value) {
      windowInnerHeight.value = window.innerHeight;
      window.addEventListener('resize', onResizeWindowInnerHeight);
    }
  });

  onBeforeUnmount(() => {
    window.removeEventListener('resize', onResizeWindowInnerHeight);
  });

  return {
    isSmartBanner,
  };
}
