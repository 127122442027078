<template>
  <DropDownList
    :type="menuItem.name"
    :title="menuItem.title"
    :is-open="isOpenedMenu"
    :show-icon="!isTablet"
    class="filter__section"
    :class="classDropDownList"
  >
    <div
      v-if="menuItem.search && isOpenedMenu"
      class="filter__search"
      :class="{ 'filter__search--finded': itemList.length > 0 }"
    >
      <div class="filter__search-container">
        <TextInput
          :value="menuItem.search.value"
          :is-valid="isValid"
          @input="menuItem.search.handleInput"
          @focus="$emit('setFalseIsFixedSubmitButton')"
          @blur="$emit('setTrueIsFixedSubmitButton')"
        />
        <SvgAsset
          v-if="isShowSearchBtn"
          class="filter__search-btn"
          :content="searchIconContent"
        />
        <button
          v-if="!isShowSearchBtn"
          class="filter__search-btn filter__search-btn_clear"
          @click="resetSearchQuery(menuItem.name)"
        >
          <img src="~assets/images/close.svg" />
        </button>
      </div>
      <p
        v-if="menuItem.search.showInstructions"
        v-show="menuItem.search.value.length < 3"
        class="filter__search__description"
      >
        Введите минимум 3 символа для поиска ингредиентов
      </p>
      <p
        v-show="isShowNotFoundDescription"
        class="filter__search__description"
      >
        По вашему запросу ничего не найдено
      </p>
    </div>

    <template v-if="isTablet">
      <div
        v-if="menuItem.twice"
        class="container__twice"
      >
        <transition-group
          class="container__first"
          tag="div"
          :name="transitionName"
        >
          <TheCheckbox
            v-for="item in itemTwiceFirst"
            :key="item.id"
            v-bind="itemBinder(item)"
            v-on="itemOn(item, 'first')"
          />
        </transition-group>
        <transition-group
          class="container__last"
          tag="div"
          :name="transitionName"
        >
          <TheCheckbox
            v-for="item in itemTwiceLast"
            :key="item.id"
            v-bind="itemBinder(item)"
            v-on="itemOn(item, 'last')"
          />
        </transition-group>
      </div>
      <template v-else>
        <div class="container__twice">
          <transition-group
            v-if="menuItem.animate"
            ref="containerOnce"
            class="container__once container__once--animate"
            tag="div"
            :name="transitionName"
          >
            <TheCheckbox
              v-for="item in itemList"
              :key="item.id"
              v-bind="itemBinder(item)"
              v-on="itemOn(item)"
            />
          </transition-group>
          <div
            v-else
            class="container__once container__once--no-animate"
          >
            <TheCheckbox
              v-for="item in itemList"
              :key="item.id"
              v-bind="itemBinder(item)"
              v-on="itemOn(item)"
            />
          </div>
        </div>
      </template>
    </template>

    <template v-else>
      <TheCheckbox
        v-for="item in itemList"
        :key="item.id"
        v-bind="itemBinder(item)"
        v-on="itemOn(item)"
      />
    </template>
  </DropDownList>
</template>

<script lang="ts">
  // TODO: Добавить типы вместо any и ts-ignore
  import type { PropType } from 'vue';
  import TheCheckbox from '~/components/filter/TheCheckbox/TheCheckbox.vue';
  import TextInput from '~/components/filter/TextInput.vue';
  import DropDownList from '~/components/filter/DropDownList/DropDownList.vue';
  import SvgAsset from '@devhacker/shared/components/SvgAsset.vue';
  import searchIconContent from '~/assets/images/search.svg?raw';
  import type {
    Listener,
    Menu,
    MenuItem,
    MenuTime,
    SelectedMenuItem,
    SelectedMenuTime,
  } from '~/containers/FilterContainer/types';
  import { useFilterStore } from '~/store/filter';
  type ItemList = MenuItem[] | MenuTime[] | SelectedMenuItem[] | SelectedMenuTime[];
  export default defineNuxtComponent({
    name: 'FilterItem',
    components: {
      TheCheckbox,
      TextInput,
      DropDownList,
      SvgAsset,
    },
    emits: ['setFalseIsFixedSubmitButton', 'setTrueIsFixedSubmitButton'],
    props: {
      menuItem: {
        type: Object as PropType<Menu>,
        required: true,
      },
      openedMenu: {
        type: String as PropType<string>,
        required: true,
      },
      select: {
        type: Function as PropType<Function>,
        required: true,
      },
      resetSearchQuery: {
        type: Function as PropType<Function>,
        required: true,
      },
      isShowSearchBtn: {
        type: Boolean as PropType<boolean>,
        required: true,
      },
    },
    data: () => ({
      isTablet: false,
      item_listeneres: [] as Listener[],
    }),
    computed: {
      searchIconContent() {
        return searchIconContent;
      },
      transitionName(): string {
        return useFilterStore().isAnimate && this.menuItem.animate ? 'flip-list-menu' : '';
      },
      isOpenedMenu(): boolean {
        return this.isTablet || this.openedMenu === this.menuItem.name;
      },
      itemList(): ItemList | undefined {
        return this.isOpenedMenu ? this.menuItem.openedList : this.menuItem.selectedList;
      },
      itemTwiceFirst(): ItemList | undefined {
        return this.menuItem.openedListTwice?.listFirst;
      },
      itemTwiceLast(): ItemList | undefined {
        return this.menuItem.openedListTwice?.listLast;
      },

      isValid(): boolean {
        const {
          searchValue,
          notFound,
          menuItem: {
            search: {
              // @ts-ignore
              minValueLength,
            },
          },
        } = this;

        if (!searchValue) {
          return true;
        }

        return minValueLength && searchValue.length < minValueLength ? true : !notFound;
      },

      isShowNotFoundDescription(): boolean {
        const {
          searchValue,
          notFound,
          menuItem: {
            search: {
              // @ts-ignore
              minValueLength,
            },
          },
        } = this;

        if (!searchValue) {
          return false;
        }

        if (minValueLength) {
          return minValueLength <= searchValue.length ? notFound : false;
        }

        return notFound;
      },

      isSelectedItems(): boolean {
        return Boolean(this.menuItem.selectedList?.length);
      },

      classDropDownList(): Record<string, boolean> {
        return {
          'drop-down__selected': this.isSelectedItems,
        };
      },

      searchValue(): string | null {
        return this.menuItem.search?.value ?? null;
      },

      openedList(): MenuItem[] | MenuTime[] {
        return this.menuItem.openedList || [];
      },

      notFound(): boolean {
        return this.menuItem.search?.notFound ?? false;
      },
    },

    watch: {
      notFound(_newValue): void {
        this.scrollContainer();
      },

      searchValue(_newValue: string | null): void {
        this.scrollContainer();
      },
    },

    mounted() {
      this.addEventListeners();
    },
    updated() {
      this.addEventListeners();
    },
    beforeUpdate() {
      this.removeEventListeners();
    },
    destroyed() {
      this.removeEventListeners();
    },
    methods: {
      async scrollContainer() {
        // @ts-ignore
        const containerOnce = this.$refs.containerOnce?.$el as HTMLElement;

        if (!containerOnce) {
          return;
        }

        const {
          searchValue,
          menuItem: {
            search: {
              // @ts-ignore
              minValueLength,
            },
          },
        } = this;

        if (
          !searchValue ||
          searchValue.length === 0 ||
          (minValueLength && searchValue.length < minValueLength)
        ) {
          containerOnce.scrollLeft = 0;
          return;
        }

        if (this.notFound) {
          containerOnce.scrollLeft = 0;
          return;
        }

        const lastSelectedItem = Array.from(
          containerOnce.querySelectorAll('.input__label--show'),
        ).slice(-1)[0] as HTMLElement;
        if (!lastSelectedItem) {
          return;
        }

        await this.$nextTick();

        containerOnce.scrollLeft = lastSelectedItem.offsetWidth * 0.5 + lastSelectedItem.offsetLeft;
      },

      inputHandler(animateCb: Function, type: string, id: string, row?: string): void {
        const _opts = { type, id, row };
        const opts = this.isTablet
          ? {
              ..._opts,
              animateCb,
            }
          : _opts;
        this.select(opts);
      },
      startHandler(value: boolean, item: MenuItem | MenuTime) {
        item.isLockAnimation = value;
      },
      itemBinder(item: MenuItem | MenuTime) {
        return {
          isTablet: this.isTablet,
          type: this.menuItem.inputType,
          value: item.id,
          label: item.title,
          checked: !!item.selected,
          isLockAnimation: item.isLockAnimation,
          activatedHandler: item.activatedHandler,
        };
      },
      itemOn(item: MenuItem, row?: 'first' | 'last') {
        return {
          change: ($e: any) => this.inputHandler($e, this.menuItem.name, item.id, row),
          start: ($e: any) => this.startHandler($e, item),
        };
      },
      addEventListeners() {
        const handler = (e: any) => {
          this.isTablet = !!e.matches;
        };
        const x = window.matchMedia('(max-width: 1023px)');
        handler(x);
        x.addEventListener('change', handler);

        this.item_listeneres.push({
          el: x,
          handler,
        });
      },
      removeEventListeners() {
        this.item_listeneres.forEach(({ el, handler }) =>
          el.removeEventListener('change', handler),
        );
      },
    },
  });
</script>

<style lang="scss">
  .drop-down__list {
    .scroll-container {
      .ps__rail-x,
      .ps__rail-x {
        display: none;
      }
    }
  }

  .filter__section {
    &:first-child {
      .filter__top {
        padding-top: 0;
        @include big-tablet {
          padding-top: 16px;
        }
      }
    }
  }
  @include big-tablet {
    .filter__section .filter__search--finded .text-input {
      margin-bottom: 6px;
    }
  }
</style>

<style lang="scss" scoped>
  $textColor: black;
  $border-color: #e7e7e7;
  $backgroundColor: white;

  .filter__section {
    padding-bottom: 24px;
    @include big-tablet {
      padding-bottom: initial;
    }

    .filter {
      $root: &;

      &__top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 16px;
        border: none;
        background-color: unset;
        cursor: pointer;
        @include big-tablet {
          padding: 16px 15px 16px 16px;
        }
      }

      &__search {
        position: relative;
        width: 100%;
        @media (max-width: 1023px) {
          position: relative;
          margin-right: 16px;
          margin-left: 16px;
        }

        &-btn {
          position: absolute;
          background: none;
          border: none;
          top: 12px;
          right: 12px;
          width: 24px;
          height: 24px;
          transition: all 0.3s ease;

          &_clear {
            cursor: pointer;

            svg path {
              transition: all 0.3s ease;
            }

            @include big-tablet {
              &:hover {
                svg path {
                  fill: #000000;
                }
              }
            }
          }

          :deep() {
            path {
              fill: #969698;
            }
            svg {
              display: block;
              pointer-events: none;
              width: 100%;
              height: 100%;
            }
          }
        }
      }

      &__search__description {
        @include fontSmallText;

        color: $textColor;
        margin: 0 0 8px;
        user-select: none;

        @include big-tablet {
          margin-bottom: 4px;
        }
      }

      &__list {
        flex-grow: 1;
        overflow-y: scroll;
        padding: 0;

        @include desktop {
          flex-grow: 0;
        }
      }
    }

    .container__twice {
      display: flex;
      flex-wrap: wrap;
      width: 100vw;
      margin-top: -8px;
      margin-bottom: -8px;

      @include hideScrollBar;
      @include big-tablet {
        width: calc(100vw - 16px);
      }
    }

    .container__once,
    .container__first,
    .container__last {
      width: min-content;
      display: flex;
      white-space: nowrap;
      gap: 0 16px;
      padding: 8px 16px;
      overflow-x: auto;
      @include hideScrollBar;
    }

    &.drop-down__closed {
      .container__twice {
        gap: initial;
      }

      &.drop-down__selected {
        padding-bottom: 24px;
      }
    }

    .flip-list-menu {
      &-move {
        transition:
          transform 0.3s ease-in-out,
          background-color 0.3s ease,
          color 0.3s linear,
          opacity 0.3s linear;
        z-index: 2;
      }
    }
  }
</style>
